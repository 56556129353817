.content-header {
    position: relative;
    display: flex;
    height: 50px;
    min-width: 350px;
    margin-bottom: 30px;
    padding: 5px 13px;
    border-radius: var(--border-radius-23);
    background-color: var(--greyscale-highlight-color);
    align-items: center;
}

.content-header .title {
    position: relative;
    padding: 0 10px;
}

.content-header .title .title-text {
    position: relative;
    margin: 0 10px;
    font-size: 15px;
    font-weight: bold;
    z-index: 2;
}

.content-header .highlight-wrapper {
    width: 100%;
    height: 15px;
    top: 12px;
    left: 0;
    position: absolute;
}

.content-header .highlight-wrapper .container {
    position: relative;
    padding: 0 17px;
    height: 14px;
}

.content-header .highlight-wrapper .container .highlight {
    background: var(--accent-color-lime);
    width: 100%;
    height: 100%;
}
