.filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: var(--border-radius-23);
    padding: 10px 20px;
    text-align: left;
    cursor: grab;
    position: relative;
    overflow: hidden;
}
.filter:last-child {
    margin-bottom: 0 !important;
}

.filter.placeholder {
    opacity: 0.5;
}

/* So that opacity doesn't get applied twice when a placeholder is inside a placeholder */
.filter-group.placeholder .filter.placeholder {
    opacity: 1;
}

.filter.is-dragging-placeholder {
    transform: rotate(-1.5deg);
}

.filter.invalid {
    border: 1px solid var(--error-color);
}

.filter p {
    margin: 0;
}

.filter .filter-name {
    flex: 0 0 25%;
    max-width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10px;
}

.filter .filter-function {
    flex: 0 0 25%;
    max-width: 25%;
}

.filter .filter-function select {
    width: 80%;
}

.filter .filter-operator {
    flex: 0 0 25%;
    max-width: 25%;
}

.filter .filter-operator select,
.filter .filter-operator input {
    width: 80%;
}

.filter .filter-value {
    flex: 0 0 25%;
    max-width: 25%;
}

.filter .filter-value select,
.filter .filter-value input {
    width: 80%;
}

.filter .filter-remove {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
}
