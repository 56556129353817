.header-wrapper {
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: space-between;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
    opacity: 1;
    z-index: 10;
    position: relative;
}

.header-wrapper .menu {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    width: 350px;
}

.header-wrapper .menu {
    display: flex;
    height: 100%;
    width: 100px;
}

.header-wrapper .menu svg {
    height: 100%;
    cursor: pointer;
}

.header-wrapper .menu .profile {
    display: flex;
    height: 100%;
    width: 85px;
}

.header-wrapper .menu .profile .picture {
    margin: auto;
    margin-left: 5px;
    width: 55px;
    border-radius: 50px;
    display: flex;
    align-content: center;
}

.header-wrapper .menu .profile .picture button {
    display: flex;
}

.header-wrapper .menu .profile .picture img {
    height: 100%;
    width: 100%;
    border-radius: 50px;
}

.header-wrapper .menu .username {
    height: 100%;
    width: 150px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
}

.header-wrapper .menu .username h2 {
    width: 100%;
    min-width: 120px;
    margin: 0;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
}

.header-wrapper .menu .username p {
    margin: 0;
    font-size: 13px;
}

.header-wrapper .logo {
    height: 100%;
    padding-left: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-wrapper .logo img {
    height: 30px;
    width: auto;
}

.header-wrapper .profile {
    position: relative;
    display: inline-block;
    margin-right: 25px;
}

.header-wrapper .dropdownbtn {
    border: none;
    cursor: pointer;
    background: var(--bg-color-blue);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 0px 2.4px;
}

.header-wrapper .dropdown .dropdown-item {
    display: flex;
    text-align: left;
    padding: 5px 15px;
}

.header-wrapper .dropdown .dropdown-item .icon {
    margin-right: 10px;
}
