.form-select select {
    width: 100%;
    height: 45px;
    border-radius: 30px;
    padding: 0 15px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('assets/icons/expand_more_24.svg') white no-repeat calc(100% - 5px);
    background-size: 30px;
}
