.account {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
}

.account .account-input-row-field {
    position: relative;
    display: flex;
    width: 250px;
    height: 45px;
    background: var(--greyscale-highlight-color) 0% 0% no-repeat padding-box;
    border-radius: 25px;
    opacity: 1;
    flex-direction: column;
    align-items: center;
}

.account .account-input-row label {
    font-weight: bold;
}

.account .account-input-row input {
    border: none;
    background: none;
    width: calc(250px - 40px);
    height: 100%;
    padding: 0 20px;
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
    color: #575757;
    outline: none;
}