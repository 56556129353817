.input .label {
    font-weight: bold;
    margin-bottom: 10px;
}

.input .form-input {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 25px;
    opacity: 1;
    box-sizing: border-box;
    transition: 0.1s border;
}

.input .form-input.default {
    background: var(--greyscale-highlight-color) 0% 0% no-repeat padding-box;
}

.input .form-input.light {
    background: #fff;
    border: 1px solid var(--dark-grey-color);
}

.input .form-input.light.disabled {
    background: var(--greyscale-highlight-color) 0% 0% no-repeat padding-box;
}

.input .form-input .form-input-icon {
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-input.editable {
    border: 2px solid var(--accent-color);
}

.form-input.non-editable,
.form-input.non-editable label,
.form-input.non-editable input {
    cursor: not-allowed;
}

.form-input input {
    border: none;
    background: none;
    width: 85%;
    height: 100%;
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
    color: #575757;
    outline: none;
}

.form-input input[type='checkbox'] {
    height: 50%;
}

.form-input input[type='checkbox'].disabled {
    cursor: not-allowed;
}

.form-input .form-input-edit {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
}