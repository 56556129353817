.kpi-builder .kpi-builder-header {
    display: flex;
    flex-direction: column;
}

.kpi-builder .kpi-builder-header .sub-header {
    font-size: 15px;
    color: var(--font-color);
}

.kpi-builder .kpi-builder-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 30px;
    background: #fff;
    width: 100%;
}

.kpi-builder-body h2 {
    margin: 0;
    margin-bottom: 30px;
}

.kpi-builder .visualization-style {
    width: 660px;
    max-width: 100%;
}

.kpi-builder-body .visualization-style .chart-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.kpi-builder-body .chart-cards .chart-card {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 15px;
    font-weight: bold;
    border: 2px solid var(--dark-grey-color);
    border-radius: var(--border-radius-23);
    padding: 20px;
    cursor: pointer;
    margin: 10px;
}

.kpi-builder-body .chart-cards .chart-card svg {
    width: 90px;
    height: 90px;
    fill: var(--dark-grey-color);
}

.kpi-builder-body .chart-cards .chart-card:hover {
    border-color: var(--accent-color);
    color: var(--accent-color);
}

.kpi-builder-body .chart-cards .chart-card:hover svg {
    fill: var(--accent-color);
}

.kpi-builder-body .chart-cards .chart-card.selected {
    border-color: var(--accent-color);
    color: var(--accent-color);
}

.kpi-builder-body .chart-cards .chart-card.selected svg {
    fill: var(--accent-color);
}

.kpi-builder-body .next-btn {
    margin-left: auto;
    margin-top: 30px;
}

.kpi-builder-body .btn-row {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.kpi-builder-body .kpi-customization {
    display: flex;
    width: 100%;
}

.kpi-customization .dimensions-sidemenu {
    display: flex;
    width: 300px;
    margin-right: 10px;
}

.kpi-customization .dimensions-sidemenu .use-denominator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 13px 0;
}

.kpi-customization .dimensions-sidemenu .input-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    margin-bottom: 7px;
}

.kpi-customization .dimensions-sidemenu .input-label:nth-child(n + 1) {
    margin-top: 10px;
}

.kpi-customization .dimensions-sidemenu .select-path-icon-btn {
    background-color: var(--accent-color);
}

.kpi-customization .chart-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
}

.kpi-customization .chart-preview-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.chart-preview-container .chart-preview-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.kpi-customization .chart-preview.sticky {
    position: sticky;
}

.kpi-customization .chart-preview .chart-type-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.kpi-customization .chart-preview .chart-type-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.kpi-customization .chart-preview .chart-type-row .kpi-name-input {
    display: flex;
}

.kpi-customization .chart-preview .chart-type-row .chart-type-btn {
    position: relative;
}

.chart-preview .chart-type-row .chart-type-btn .chart-type-icon {
    display: flex;
    margin-left: 5px;
}

.chart-preview .dimension-errors {
    width: 100%;
    margin-top: 10px;
}

.chart-preview .action-btns {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.chart-preview .action-btns .preview-btn {
    margin-right: 10px;
}
