.app-builder .preview {
    height: 0;
    width: 100%;
    margin-bottom: 10px;
}

.app-builder .preview.active {
    min-height: 150px;
    height: auto;
    max-height: 600px;
}

.app-builder .editor-blocks * {
    font-family: monospace !important;
}

.app-builder .editor-blocks {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.app-builder .editor-blocks .editor-block {
    display: flex;
}

.app-builder .editor-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.app-builder .editor-labels {
    display: flex;
    align-items: center;
}

.app-builder .editor-labels .label {
    height: 100%;
    padding: 0 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.app-builder .editor-labels .label:hover {
    background-color: #c7c7c7;
}

.app-builder .app-builder-save {
    margin: 0 10px;
}

.app-builder .header-buttons {
    display: flex;
    align-items: center;
}

.app-builder .app-builder-save-status svg {
    transition: opacity 0.3s;
}
