.input-error {
    padding-top: 2px;
    padding-left: 15px;
    color: var(--error-color);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.input-error .input-error-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.input-error .input-error-icon svg {
    fill: var(--error-color);
}
