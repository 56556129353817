.alert {
    border-radius: var(--border-radius-23);
    border: 1px solid transparent;
    margin-bottom: 10px;
    padding: 10px 20px;
    font-size: 13px;
    color: var(--font-color);
    display: flex;
    align-items: center;
}

.alert.alert-error {
    background-color: var(--bg-color-error);
    border-color: var(--border-color-error);
}

.alert.alert-success {
    background-color: var(--bg-color-success);
    border-color: var(--border-color-success);
}

.alert.alert-warning {
    background-color: var(--bg-color-warning);
    border-color: var(--border-color-warning);
}

.alert.text-start {
    justify-content: start;
}

.alert.text-center {
    justify-content: center;
}

.alert.text-end {
    justify-content: end;
}
