.unify {
    height: 100%;
}

.unify .action-btns {
    display: flex;
    justify-content: center;
    align-items: center;
}

.unify .unify-header {
    margin-bottom: 30px;
}

.unify .unify-loader {
    height: 100%;
    display: flex;
    align-items: center;
}

.unify .unify-welcome {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 20px;
    padding: 20px;
}

.unify-welcome .content-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
}

.unify-welcome .content-col h1 {
    font-size: 24px;
    margin: 0;
}

.unify-welcome .content-col p {
    font-size: 18px;
    margin: 20px 0;
}

.unify-welcome .content-col .btn-row {
    width: 100%;
    display: flex;
    justify-content: center;
}
