.import {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.import .import-input-label {
    font-weight: bold;
    text-align: start;
}

.import-step-one .import-btn-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.import .selected-file-header {
    margin-top: 1rem;
}

.import .error-text {
    font-size: 12px;
    color: red;
}

.import .csv-file-input {
    margin-top: 1rem;
}

.import .import-step-two {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.import .import-datatable .rdt_TableRow {
    padding: 5px 0;
}

.import .import-step-two .import-btn-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}
