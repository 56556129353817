.table-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
}

.table-header p {
    margin-right: 40px;
}

.table-header .header-icons {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}
