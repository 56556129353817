#userapp {
    padding: 30px;
}
#userapp .app-content {
    width: 100%;
    min-height: 400px;
    height: auto;
    max-height: 1000px;
    border: none;
    overflow-y: auto;
}
