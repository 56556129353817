.content-panel {
    text-align: left;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: var(--border-radius-23);
    overflow: hidden;
    max-width: 100%;
}

.content-panel.light-grey {
    background: var(--greyscale-color);
}

.content-panel.white {
    background: #fff;
}

.content-panel .content-panel-header {
    padding: 12px 30px;
    border-bottom: 2px solid var(--greyscale-highlight-color);
    font-weight: bold;
    font-size: 23px;
    color: var(--accent-color);
}

.content-panel .content-panel-content {
    overflow: hidden;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}
