.activate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px 30px;
}

.activate .logo {
    height: 50px;
    width: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.activate h2 {
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
    letter-spacing: 0px;
    color: var(--accent-color);
}

.activate p {
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
}

.activate .activate-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.activate .activate-input {
    margin-bottom: 5px;
}

.activate .activate-btn {
    font: normal normal 20px/15px Poppins;
}

.activate .error,
.activate .success {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.activate .error .error-icon,
.activate .success .success-icon {
    margin-bottom: 10px;
}
