.unify-setup .step-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 300px;
    margin: auto;
}

.unify-setup .loader-container {
    min-height: 300px;
    display: flex;
    align-items: center;
}

.unify-setup .step {
    padding: 15px 30px;
}

.unify-setup .step-1 .continue-btn {
    margin-top: 10px;
}

.unify-setup .step-2 .unify-errors {
    margin-bottom: 20px;
}

.unify-setup .step .btn-row {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.unify-setup .step-2 .unify-setup-table {
    background-color: var(--greyscale-color);
}
