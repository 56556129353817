.loading-icon-btn {
    width: auto;
    position: relative;
}
.loading-icon-btn .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    border: none;
    background-color: #c7c7c7;
}
