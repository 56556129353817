.fieldselection {
    width: 100%;
    display: flex;
    min-height: 300px;
}

.fieldselection .fields-col {
    margin-right: 15px;
}

.fieldselection .fields-col .cdp-id {
    font-weight: bold;
}

.fieldselection .fields-col .fields-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.fields-col .fields-col-pills .rdt_TableCell {
    font-weight: bold;
}

.fields-col .fields-col-pills .rdt_Table,
.fields-col .fields-col-pills .rdt_ExpanderRow {
    background-color: transparent;
}

.selected-fields-col {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.selected-fields-col .required-fields,
.selected-fields-col .selected-fields {
    flex: 1;
    background-color: var(--greyscale-color);
    border-radius: var(--border-radius-23);
}

.selected-fields-col .required-fields {
    padding: 30px;
}

.selected-fields-col .required-fields .required-field-row {
    display: flex;
    align-items: center;
}

.selected-fields-col .required-fields .required-field-row .required-field-pill {
    cursor: default;
    padding: 0;
    width: 275px;
    background-color: #fff;
    border-radius: var(--border-radius-23);
    border: 1px solid #d1d1d1;
    height: 38px;
}

.selected-fields-col .required-fields .required-field-row .required-field-pill.required-pill-error {
    border-color: var(--error-color);
}

.selected-fields-col .required-fields .required-field-row > p {
    min-width: 100px;
    margin-right: 10px;
}

.selected-fields-col .selected-fields .selected-fields-drop-area {
    padding: 30px;
}

.selected-fields-col .selections-row {
    display: flex;
}

.selected-fields-col .selections-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.selected-fields-col .selections-col .remove-selections-wrapper {
    padding: 0 5px;
}
