.tbl-table {
    width: 100%;
    display: table;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;
    height: 100%;
    max-width: 100%;
}

.tbl-thead {
    display: flex;
    height: 50px;
    width: 100%;
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 500;
}

.tbl-thead .tbl-tr {
    border: none !important;
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    width: 100%;
}

.tbl-thead .tbl-tr .tbl-th {
    -webkit-box-flex: 1;
    flex: 1 0 0px;
    max-width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0;
    margin-right: 10px;
}

.tbl-tbody {
    display: flex;
    flex-direction: column;
}

.tbl-tbody .tbl-tr {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(255, 255, 255);
    min-height: 48px;
}

.tbl-tbody .tbl-tr .tbl-td {
    margin-right: 10px;
}

.tbl-tr {
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.tbl-th {
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}