.button {
    position: relative;
    color: white;
    fill: #fff;
    border: none;
    border-radius: var(--border-radius-23);
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:not(:disabled) {
    cursor: pointer;
}

.button .button-content {
    display: flex;
    align-items: center;
}

.button .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.button.primary {
    background: var(--accent-color) 0% 0% no-repeat padding-box;
}

.button.secondary {
    background-color: #fff;
    color: var(--font-color);
    fill: var(--font-color);
    border: 1px solid var(--font-color);
}

.button.danger {
    background: var(--error-color) 0% 0% no-repeat padding-box;
}

.button:not(:disabled):hover {
    background: #000 0% 0% no-repeat padding-box;
}

.button:not(:disabled):hover svg {
    fill: #fff;
}

.button.secondary:not(:disabled):hover {
    color: #fff;
}
