.filter-group {
    cursor: grab;
}

.filter-group.root {
    height: 100%;
    padding: 10px 0;
    cursor: default;
}

.filter-group.placeholder {
    opacity: 0.5;
}

.filter-group.is-dragging-placeholder {
    transform: rotate(-1.5deg);
}

/* So that opacity doesn't get applied twice when a placeholder is inside a placeholder */
.filter-group.placeholder .filter-group.placeholder {
    opacity: 1;
}

.filter-group:last-child {
    margin-bottom: 0 !important;
}

.filter-group .filter-group-body {
    text-align: left;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}

.filter-group .filter-group-body .operator {
    width: 100px;
    height: auto;
    border: 1px solid #ccc;
    border-radius: var(--border-radius-23);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background: #fff;
    position: relative;
    overflow: hidden;
}

.filter-group .filter-group-body .operator.invalid {
    border: 1px solid var(--error-color);
    height: 100%;
}

.filter-group .filter-group-body .operator .remove {
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 7px;
}

.filter-group .filter-group-body .filters {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
}

.filter-group .filter-group-body .filters .filter-placeholder {
    height: 50px;
    width: 100%;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: var(--border-radius-23);
}
