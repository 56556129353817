.components-demo {
    display: flex;
    flex-direction: column;
}

.components-demo h2 {
    text-decoration: underline;
}

.components-demo .content-header-save {
    margin: 0 10px;
}

.components-demo .content-panel-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}
