.version-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    bottom: 10px;
    right: 15px;
    height: 24px;
    text-align: left;
    text-decoration: none;
    border-radius: 16px;
    transition: all .2s ease-in-out;
}

.version-wrapper:hover {
    width: 110px;
    background-color: black;
    transform: scale(1.1);
}

.version-wrapper:hover p {
    display: inline;
    text-wrap: nowrap;
}

.version-wrapper p {
    margin: 0;
    font-size: 9px;
    color: white;
    display: none;
    width: 86px;
    text-align: left;
    padding-left: 8px;
}