.unify-results .unify-results-container {
    padding: 15px 30px;
}

.unify-results-container .loader-container {
    min-height: 300px;
    display: flex;
    align-items: center;
}

.unify-results-container .action-btns {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 0;
}

.unify-results-container .number-col {
    font-size: 16px;
    font-weight: bold;
}

.unify-results-container .attr-row {
    display: flex;
    gap: 25px;
    padding: 10px 0;
}

.unify-results-container .attr-row .attr-col {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.unify-results-container .attr-row .attr-col .attr-name {
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    height: 21px;
}

.unify-results-container .attr-row .attr-col .attr-value {
    height: 45px;
    display: flex;
    align-items: center;
}

.unify-results-container .results-error,
.unify-results-container .no-results {
    width: 100%;
    padding: 40px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    background: var(--greyscale-color);
}

.unify-results .results-loader {
    width: 100%;
    padding: 40px;
    display: flex;
    justify-content: center;
    background: var(--greyscale-color);
}
