.flow-wrapper .section {
    margin-bottom: 20px;
    padding: 0 30px;
}

.flow-wrapper .header-col {
    display: flex;
    flex-direction: column;
}

.flow-wrapper .header-col .sub-header {
    font-size: 13px;
    color: var(--font-color);
}

.flow-wrapper .new-flow {
    padding: 30px 0 0 0;
}

.flow-wrapper .schedule-header {
    padding: 12px 30px;
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 23px;
    color: var(--accent-color);
    border-bottom: 2px solid var(--greyscale-highlight-color);
}

.flow-wrapper .section-header-name {
    display: flex;
}

.flow-wrapper .section-header-name-status {
    width: 40px;
    padding: 5px;
    display: flex;
    justify-content: center;
}

.flow-wrapper .section .app-select {
    min-height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.flow-wrapper .section .app-select .app-select-item {
    width: 105px;
    height: 150px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.flow-wrapper .section .app-select .app-select-item.selected {
    box-sizing: border-box;
    border: 1px solid var(--accent-color);
    border-radius: 3px;
    background-color: rgba(var(--accent-color-rgb), 0.4);
    transition: all 0.1s ease-in;
}

.flow-wrapper .section .app-select .app-icon {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    position: relative;

    transition: transform 0.3s ease;
}

.flow-wrapper .section .app-select .app-icon:hover {
    transform: scale(1.1);
}

.flow-wrapper .section .schedule-select {
    display: flex;
    align-items: center;
}

.flow-wrapper .section .schedule-btn {
    margin-right: 10px;
}

.flow-wrapper .section-header {
    font-size: 16px;
    margin-bottom: 10px;
}

.save-btn-wrapper {
    margin-top: 10px;
}

.save-btn-wrapper button {
    width: 302px;
}

.flow-wrapper .error-msg {
    color: var(--error-color);
    height: 30px;
    display: flex;
    align-items: center;
}

.flow-wrapper .flow-setup-btns {
    display: flex;
    justify-content: space-between;
}

.flow-wrapper .meta-data-section {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 15px;
    padding: 12px 30px;
    background-color: var(--greyscale-color);
    border-radius: var(--border-radius-23);
}

.flow-wrapper .meta-data-section h2 {
    margin: 0 50px 0 0;
}

.flow-wrapper .meta-data-section .meta-data-section-requirement {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.flow-wrapper .meta-data-section .meta-data-section-requirement p {
    white-space: nowrap;
    margin-right: 30px;
}

.flow-wrapper .name-loader {
    align-items: normal;
}
