.pill {
    display: block;
    text-align: center;
    font-size: 14px;
    border: 2px solid var(--font-color);
    padding: 10px 8px;
    border-radius: var(--border-radius-23);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--font-color);
    transition: white-space 1s ease, max-height 0.75s ease, overflow 1s ease;
    max-height: 45px;
}

.pill.primary {
    background-color: var(--accent-color);
    color: #fff;
}

.pill.primary-light {
    background-color: rgba(var(--accent-color-rgb), 0.5);
}

.pill.secondary {
    background-color: #fff;
}

.pill.danger {
    background-color: var(--error-color);
    color: #fff;
}

.pill.accent {
    background-color: var(--accent-color-lime);
}

.pill.success {
    background-color: var(--success-color);
}

.pill:hover,
.pill.expanded {
    white-space: normal;
    overflow-wrap: break-word;
    overflow: visible;
    max-height: 200px;
}
