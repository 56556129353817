.file-input {
    margin-bottom: 20px;
}
.file-input .file-input-label {
    font-weight: bold;
    margin-bottom: 10px;
}
.file-input .file-input-drop {
    position: relative;
    display: flex;
    align-items: center;
    height: 45px;
    background: var(--greyscale-highlight-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--greyscale-highlight-color);
    border-radius: 25px;
    box-sizing: border-box;
    transition: 0.1s border;
    font-size: 15px;
    padding: 0 15px;
    color: #575757;
}
.file-input .file-input-icon {
    margin-right: 5px;
    fill: #575757;
}
.file-input .file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.file-input .remove-file-btn {
    fill: #575757;
    z-index: 2;
    cursor: pointer;
}
.file-input .file-input-drop:hover {
    border: 1px solid #575757;
}
.file-input .file-input-drop input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
