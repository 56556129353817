.segment-preview {
    padding: 20px;
}

.segment-preview .header {
    margin-top: 0;
}

.segment-preview .loader {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.segment-preview .no-results {
    font-size: 14px;
}

.chart-select {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chart-select .chart-btns {
    display: flex;
}

.chart-select .chart-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    width: 90px;
    cursor: pointer;
}

.chart-select .chart-btn .chart-icon {
    width: 50px;
    padding-bottom: 10px;
}
.chart-select .chart-btn .chart-name {
    font-weight: bold;
}

.segment-preview .visualization-container {
    max-width: 1400px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
}

.segment-preview .select-path-btn {
    background-color: var(--accent-color);
    margin-left: 10px;
}
