.forgot {
    display: flex;
    align-items: stretch;
    height: 100%;
    padding: 35px;
}

.forgot .forgot-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
}

.forgot .forgot-logo {
    margin-bottom: 20px;
}

.forgot h2 {
    margin-bottom: 20px;
    color: var(--accent-color);
}

.forgot .forgot-input {
    margin-bottom: 10px;
}

.forgot .forgot-btn {
    font: normal normal 20px/15px Poppins;
}

.forgot .input .form-input {
    padding: 0 8px;
}