.settings-wrapper {
    position: relative;
}

.settings-wrapper h3 {
    padding-bottom: 15px;
    border-bottom: 1px solid var(--greyscale-highlight-color);
    margin: 0;
}

.settings-body {
    display: flex;
    min-height: 300px;
}

.settings-menu {
    width: 200px;
    min-height: 300px;
    border-right: 1px solid var(--greyscale-highlight-color);
}

.settings-menu ul {
    margin-top: 0;
    padding: 0;
    text-align: left;
    list-style-type: none;
}

.settings-menu ul li {
    padding: 12px 30px;
    cursor: pointer;
}

.settings-menu ul li.selected {
    background: var(--greyscale-highlight-color) 0% 0% no-repeat padding-box;
}

.settings-menu ul li:hover {
    background: var(--greyscale-highlight-color) 0% 0% no-repeat padding-box;
}

.settings-pages {
    width: 100%;
    margin-left: 10px;
    padding: 30px;
}