.download-wrapper .header-col {
    display: flex;
    flex-direction: column;
}

.download-wrapper .header-col .sub-header {
    font-size: 13px;
    color: var(--font-color);
}

.download-wrapper .download-field-selection {
    padding: 30px;
    background-color: white;
}

.download-wrapper .download-field-selection .download-btn-wrapper {
    margin-top: 10px;
}
