.apps-wrapper {
    display: flex;
    flex-direction: column;
}

.apps-wrapper .apps-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.apps-wrapper .apps-header .menu-items {
    display: flex;
    flex-direction: row;
}

.apps-wrapper .apps-menu-item {
    padding: 10px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 50%;
}

.apps-wrapper .apps-menu-item:hover {
    background-color: #c7c7c7;
}

.apps-wrapper .apps-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}

.apps-wrapper .content-panel-content {
    padding: 30px;
}

.apps-app {
    display: flex;
    width: 100px;
    min-width: 100px;
    height: 100px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
    position: relative;

    transition: transform 0.3s ease;
}

.apps-app:hover {
    transform: scale(1.1);
}

.apps-app small {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.apps-app .app-menu-btn {
    position: absolute;
    top: 5px;
    right: 5px;
}
