.icon-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 50%;
    border: none;
    background: none;
}

.icon-btn:hover {
    background-color: #c7c7c7;
}