.unify-exceptions .objects-table {
    margin: 20px 0;
}

.unify-exceptions .tooltip {
    width: 100%;
}

.unify-exceptions .tooltip .truncated-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
