.nav-wrapper {
    width: 250px;
    z-index: 0;
    transition: all 0.5s;
    height: calc(100vh - 68px);
}

.nav-wrapper ul {
    margin-top: 0px;
    padding-top: 35px;
    padding-left: 25px;
    list-style-type: none;
    display: flex;
    height: 100%;
    flex-direction: column;
    background: var(--greyscale-color);
}

.nav-wrapper ul li {
    margin-bottom: 2px;
}

.nav-wrapper ul li .link {
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 20px;
    height: 45px;
    overflow: hidden;
}

.nav-wrapper ul li .link .icon {
    height: 100%;
    width: 24px;
}

.nav-wrapper ul li .link p {
    display: block;
    margin-left: 20px;
}

.nav-wrapper ul li .link:hover {
    background: var(--greyscale-highlight-color);
    border-radius: var(--border-radius-23) 0 0 var(--border-radius-23);
}

.nav-wrapper ul li .link.selected {
    width: auto;
    height: 45px;
    background: var(--greyscale-highlight-color) 0% 0% no-repeat padding-box;
    border-radius: 23px 0px 0px 23px;
    opacity: 1;
}

.nav-wrapper ul li .link.selected .selection-highlight {
    height: 100%;
    width: 100%;
}

.nav-wrapper ul li .link.selected .selection-highlight span {
    display: block;
    float: right;
    height: 100%;
    width: 5px;
    background-color: var(--accent-color);
}

.nav-wrapper .collapse-toggle {
    padding-bottom: 35px;
    padding-left: 30px;
    transition: all 0.5s;
}

.nav-wrapper .sticky-collapse-toggle {
    position: fixed;
    bottom: 20px;
    left: 25px;
    padding-left: 30px;
    transition: all 0.5s;
}

.nav-wrapper.collapsed {
    width: 125px;
}

.nav-wrapper.collapsed .collapse-toggle {
    padding-left: 0;
}

.nav-wrapper.collapsed .sticky-collapse-toggle {
    padding-left: 0px;
}

.nav-wrapper.collapsed ul li .title {
    display: none;
}
