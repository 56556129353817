.select-join-path-modal {
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 20px;
}

.select-join-path-modal h2 {
    margin-top: 0;
}

.select-join-path-modal .join-paths {
    display: flex;
    overflow: auto;
}

.select-join-path-modal .join-paths .path-container {
    padding: 0 5px;
    width: 160px;
}

.select-join-path-modal .join-paths .path-container .path {
    background-color: var(--greyscale-highlight-color);
    border-radius: var(--border-radius-23);
    padding: 5px;
    cursor: pointer;
}

.select-join-path-modal .join-paths .path-container .path.selected {
    background-color: rgba(var(--accent-color-rgb), 0.5);
}

.path-container .path .arrow-up,
.path-container .path .arrow-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
}

.path-container .path .arrow-up .arrow-body,
.path-container .path .arrow-down .arrow-body {
    width: 4px;
    height: 15px;
    background: var(--font-color);
    display: inline-block;
}

.path-container .path .arrow-up .arrow-point {
    border: solid var(--font-color);
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-135deg);
}

.path-container .path .arrow-up .arrow-body.top {
    margin-top: -9px;
}

.path-container .path .arrow-down .arrow-point {
    border: solid var(--font-color);
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
}

.path-container .path .arrow-down .arrow-body.bottom {
    margin-bottom: -9px;
}

.path-container .path .table-node {
    font-size: 14px;
    border: 2px solid var(--font-color);
    padding: 10px 8px;
    border-radius: var(--border-radius-23);
    background-color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.path-container .path .table-node:hover {
    white-space: normal;
    overflow: visible;
}

.path-container .path .table-fk {
    font-size: 10px;
}

.select-join-path-modal .button-row {
    margin-top: 10px;
    margin-right: 5px;
    display: flex;
    justify-content: flex-end;
}
