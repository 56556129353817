.content-wrapper {
    display: flex;
    flex: 1;
}

.content-wrapper .content-container {
    font-size: 13px;
    min-width: 385px;
    width: 100%;
    height: auto;
    padding: 35px;
    transition: margin 0.5s;
    background-color: white;
    overflow: hidden;
}
