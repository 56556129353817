.dashboard {
    padding: 20px 50px;
    display: flex;
    align-items: center;
}

.dashboard .welcome-text {
    font: normal normal 600 55px/55px Poppins;
    color: #0075eb;
}

@media only screen and (max-width: 1015px) {
    .dashboard .welcome-img {
        display: none;
    }

    .dashboard .welcome-text {
        font-size: 2.5em;
        text-align: center;
        width: 100%;
    }
}
