@font-face {
    font-family: Poppins;
    src: url('assets/fonts/poppins/Poppins-Black.ttf');
    font-style: black;
}

@font-face {
    font-family: Poppins;
    src: url('assets/fonts/poppins/Poppins-Medium.ttf');
    font-style: medium;
}

@font-face {
    font-family: Poppins;
    src: url('assets/fonts/poppins/Poppins-Regular.ttf');
    font-style: normal;
}

:root {
    --font-color: #0f0f0f;
    --bg-color-blue: #dfeeff;
    --accent-color: #0075eb;
    --accent-color-rgb: 0, 117, 235;
    --accent-color-lime: #cff800;
    --dark-grey-color: #575757;
    --greyscale-color: #fbfbfb;
    --greyscale-highlight-color: #f2f2f2;
    --error-color: #ff5252;
    --error-color-rgb: 255, 82, 82;
    --success-color: #4c9f70;
    --bg-color-error: #f8d7da;
    --border-color-error: #f5c2c7;
    --bg-color-success: #d1e7dd;
    --border-color-success: #badbcc;
    --bg-color-warning: #fff3cd;
    --border-color-warning: #ffecb5;

    --border-radius-23: 23px;
}

body {
    padding: 0;
    margin: 0;
    background-color: var(--greyscale-highlight-color);
    font-family: Poppins;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body * {
    font-family: Poppins;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.public-wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    color: white;
    width: 100px;
    height: 45px;
    border: none;
    background: var(--accent-color) 0% 0% no-repeat padding-box;
    border-radius: 23px;
    cursor: pointer;
}

.btn:hover {
    background: black 0% 0% no-repeat padding-box;
}
