.single-value {
    margin: 20px;
    background-color: var(--greyscale-highlight-color);
    border-radius: var(--border-radius-23);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.single-value .title {
    margin-bottom: 0;
    font-size: 30px;
}

.single-value .value {
    font-size: 80px;
    color: var(--accent-color);
}

.single-value .fraction {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.single-value .fraction .fraction-den {
    border-top: 8px solid var(--font-color);
    padding: 0 15px;
}
