.scheduler-wrapper .scheduler-presets {
    display: flex;
}

.scheduler-wrapper .scheduler-presets .preset-btn {
    margin-right: 10px;
}

.scheduler-wrapper .scheduler-presets .preset-btn.selected {
    background-color: #000;
}

.scheduler-wrapper .scheduler-selection {
    display: flex;
    flex-direction: column;
}

.scheduler-wrapper .scheduler-selection-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}

.scheduler-wrapper .scheduler-selection-row .repeat-on-btn {
    height: auto;
    margin-right: 10px;
}

.scheduler-wrapper .scheduler-selection-row .repeat-on-btn.selected {
    background-color: #000;
    color: #fff;
}

.scheduler-wrapper .scheduler-selection-row .form-input,
.scheduler-wrapper .scheduler-selection-row .form-select {
    margin-right: 10px;
}

.scheduler-wrapper .scheduler-selection-row-title {
    width: 120px;
    font-size: 16px;
}

.scheduler-wrapper .scheduler-selection-radio-group {
    display: flex;
    flex-direction: column;
}

.scheduler-wrapper .scheduler-selection-radio-group-item {
    height: 40px;
    display: flex;
    align-items: center;
}

.scheduler-wrapper .scheduler-selection-radio-group-item label {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.scheduler-wrapper .scheduler-selection-radio-group-item input[type='radio'] {
    cursor: pointer;
    line-height: 38px;
    margin-right: 15px;
    margin-bottom: 5px;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}

.scheduler-wrapper input[type='number'] {
    padding: 0 10px;
}
