.app {
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

body {
    background-color: var(--greyscale-color);
}
