.app-import .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.app-import .header-buttons {
    display: flex;
    align-items: center;
}

.app-import .import-btn {
    margin-left: 10px;
}

.app-import .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
}
