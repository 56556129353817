.modal {
    inset: 50% auto auto 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-radius: 23px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    outline: none;
    padding: 20px;
    max-height: 95%;
}

.modal h2 {
    margin: 0;
}

.modal .modal-content {
    padding: 20px 0;
}

.modal .confirmation-btn-row {
    display: flex;
    justify-content: space-between;
}

.modal .info-btn-row {
    display: flex;
    justify-content: center;
}
