.users-content {
    min-width: 300px;
}

.users-content .rdt_ExpanderRow {
    transition: all 1s;
}

.users-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.users-header button {
    margin-left: 10px;
}

.new-user .new-user-header {
    padding: 12px 30px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 23px;
    color: var(--accent-color);
    border-bottom: 2px solid var(--greyscale-highlight-color);
}
