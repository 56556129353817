.connections-wrapper .section .app-select {
    min-height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.connections-wrapper .section .app-select .app-select-item {
    width: 105px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    padding: 10px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.connections-wrapper .section .app-select .app-select-item:hover {
    overflow: visible;
    white-space: normal;
}

.connections-wrapper .section .app-select .app-select-item p {
    width: 100%;
    text-align: center;
}

.connections-wrapper .section .app-select .app-icon {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    position: relative;

    transition: transform 0.3s ease;
}

.connections-wrapper .section .app-select .app-icon:hover {
    transform: scale(1.1);
}

.connections-wrapper .section .header {
    padding: 12px 10px;
    font-weight: bold;
    font-size: 23px;
    color: var(--font-color);
    border-bottom: 2px solid var(--greyscale-highlight-color);
}

.connections-wrapper .app-status-icon {
    position: absolute;
    right: -3px;
    bottom: -16px;
}