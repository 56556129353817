.data-table {
    width: 100%;
}

.data-table.header-bg .rdt_TableHeadRow {
    height: 45px;
    min-height: auto !important;
    margin-bottom: 30px;
    background: var(--greyscale-highlight-color) 0% 0% no-repeat padding-box;
    background-color: var(--greyscale-highlight-color) !important;
    border-radius: var(--border-radius-23);
}

.data-table.clickable-rows .rdt_TableRow {
    cursor: pointer;
}