.slider {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.slider .slider-input {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 15px;
    background: #ddd;
    outline: none;
    border-radius: var(--border-radius-23);
}

.slider .slider-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: var(--accent-color);
    cursor: pointer;
    border-radius: 5px;
}

.slider .slider-input::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: var(--accent-color);
    cursor: pointer;
    border-radius: 5px;
}

.slider .slider-value {
    font-size: 15px;
    font-weight: 600;
}
