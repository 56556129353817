.user-form {
    width: 530px;
    padding: 10px 55px;
    display: flex;
    flex-direction: column;
}

.user-form .user-form-item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
}

.user-form .user-form-item.with-error {
    margin-bottom: 0;
}

.user-form .btn-row {
    display: flex;
    justify-content: flex-end;
}

.user-form .user-input-title {
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 15px;
    margin: 0;
}

.user-form .user-input-title.activation-title {
    background-color: #ffff62a1;
    padding: 0 5px;
    border-radius: 5px;
}
