.editable-highlight-field {
    cursor: pointer;
    display: flex;
}

.editable-highlight-field .editable-field-wrapper {
    position: relative;
    max-width: calc(100% - 24px);
}

.editable-highlight-field .editable-field {
    position: relative;
    margin: 0 10px;
    font-size: 15px;
    font-weight: bold;
    z-index: 2;
}

.editable-highlight-field .highlight-wrapper {
    width: 100%;
    height: 15px;
    top: 12px;
    left: 0;
    position: absolute;
}

.editable-highlight-field .highlight-wrapper .highlight {
    position: relative;
    background: var(--accent-color-lime);
    height: 100%;
    margin: 0 5px;
}

.editable-highlight-field .edit-icon {
    cursor: pointer;
    opacity: 0.2;
    display: flex;
    align-items: center;
}

.editable-highlight-field:hover .edit-icon {
    opacity: 1;
}

.editable-highlight-field .editable-field input {
    border: none;
    background: none;
    outline: none;
    font-weight: bold;
    width: 100%;
    font-size: 15px;
    border-bottom: 2px solid black;
}

.editable-highlight-field .editable-field p {
    text-align: start;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
