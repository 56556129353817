.selected-table-property-pill {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.selected-table-property-pill .selected-pill {
    cursor: default;
    padding: 0 20px;
    width: 275px;
    height: 38px;
    border-radius: var(--border-radius-23);
    border: 2px solid var(--accent-color);
    background-color: var(--accent-color);
    display: flex;
    align-items: center;
    position: relative;
}

.selected-table-property-pill .selected-pill.selected-pill-error {
    border-color: var(--error-color);
    background-color: rgba(var(--error-color-rgb), 0.7);
}

.selected-table-property-pill .selected-pill .selected-pill-icon {
    margin-right: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
}

.selected-table-property-pill .selected-pill .selected-pill-text {
    flex: 1;
    color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.selected-table-property-pill .selected-pill .selected-pill-table {
    line-height: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0.5;
}

.selected-table-property-pill .selected-pill .selected-pill-property {
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.selected-table-property-pill .selected-pill-more {
    display: flex;
}
