.search-wrapper {
    display: flex;
    width: 253px;
    height: 45px;
    background: var(--greyscale-highlight-color) 0% 0% no-repeat padding-box;
    border-radius: 23px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    overflow: hidden;
    padding: 0 15px;
    opacity: 1;
}

.search-wrapper .icon {
    height: 100%;
    margin-right: 5px;
}

.search-wrapper input {
    color: #575757;
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    outline: none;
    font-size: 15px;
}