.login {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login .login-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px 30px;
    width: 420px;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: var(--border-radius-23);
}

.login .login-card form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login .login-card .logo {
    height: 50px;
    width: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.login .login-card h2 {
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
    letter-spacing: 0px;
    color: var(--accent-color);
}

.login .login-card .login-input {
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
}

.login .login-card .login-input .form-input {
    padding: 0 15px;
}

.login .login-card .login-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login .login-card .login-btn {
    font: normal normal 20px/15px Poppins;
}

.login .login-card .login-btn:hover {
    background: linear-gradient(90deg, rgb(39, 143, 255) 0%, rgb(12, 99, 250) 100%);
}

.login .login-card .login-forgot {
    margin-top: 10px;
    color: #575757;
}

.login .login-card .login-forgot:hover {
    color: var(--accent-color);
}