.card-list {
    position: absolute;
    z-index: 99999;
    background: var(--greyscale-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    font: normal normal normal 14px/20px Poppins;
}

.card-list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.card-list ul li {
    padding: 5px 10px;
    cursor: pointer;
}

.card-list ul li.show-divider {
    border-top: 1px solid var(--dark-grey-color);
}

.card-list ul li:hover {
    background-color: rgba(0, 0, 0, 0.14);
}

.card-list ul li:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.card-list ul li:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
