.segmentation-list .rdt_TableRow .rdt_TableCell .action-btns {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* This is needed to see the tooltip components in the datatable. */
.segmentation-list .rdt_TableRow .rdt_TableCell:last-of-type div:first-child,
.segmentation-list .gelpCx {
    overflow: visible;
}

.segmentation-list .segmentation-list-header {
    margin-bottom: 30px;
}

.segmentation-list .flow-btn {
    position: relative;
}

ul.segmentation-modal-flows {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.segmentation-modal-flows li {
    height: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid #EEEEEE;
}

ul.segmentation-modal-flows li:hover {
    background-color: #EEEEEE;
}