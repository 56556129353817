.object-editor .object-editor-table .rdt_TableHeadRow,
.object-editor .object-editor-table .rdt_TableRow {
    border: none !important;
}

.object-editor .object-editor-table .rdt_TableHeadRow {
    font-weight: bold;
}

.object-editor .object-editor-table .rdt_TableHeadRow .rdt_TableCol {
    padding-right: 10px;
}

.object-editor .object-editor-table .rdt_TableRow {
    margin: 5px 0;
}

.object-editor .object-editor-save {
    margin: 0 10px;
}

.object-editor .object-editor-save-status {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    right: 60px;
}

.object-editor .object-editor-save-status svg {
    transition: opacity 0.3s;
}

.object-editor .object-editor-junction {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
}

.object-editor .fk-label {
    height: 35px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.object-editor .fk-label .fk-label-icon {
    margin-right: 5px;
}

.object-editor .fk-col {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.object-editor .fk-input {
    margin-top: 5px;
}

.object-editor .fk-info {
    margin-top: 5px;
    display: flex;
    align-items: center;
    height: 35px;
}

.object-editor .action-btns-row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
