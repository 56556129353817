.draggable-pill {
    cursor: grab;
    padding: 0 20px;
    font-size: 14px;
    width: auto;
    height: 38px;
    border: 1px solid #d1d1d1;
    border-radius: var(--border-radius-23);
    background-color: #fff;
    display: flex;
    align-items: center;
    overflow: visible;
    margin-bottom: 5px;
    transform: rotate(0deg);
}

.draggable-pill.dragging {
    transform: rotate(-5deg);
    border: 2px solid var(--accent-color);
}

.draggable-pill .pill-drag-icon {
    margin-right: 5px;
    display: flex;
    align-items: center;
}

.draggable-pill .pill-type-icon {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.draggable-pill .pill-text {
    color: var(--dark-grey-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.draggable-pill .pill-text:hover {
    white-space: normal;
    width: auto;
    overflow: visible;
}

.is-dragging .draggable-pill.dragging {
    background-color: var(--greyscale-highlight-color);
    transform: rotate(0deg);
    border: 1px solid #d1d1d1;
}

.is-dragging .draggable-pill .pill-drag-icon,
.is-dragging .draggable-pill .pill-text {
    visibility: hidden;
}
