.accordion {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.accordion .accordion-loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accordion .accordion-item {
    margin-top: 5px;
    border-radius: var(--border-radius-23);
    box-shadow: 0px 3px 6px #00000029;
}

.accordion .accordion-item:first-child {
    margin-top: 0;
}

.accordion .accordion-item-header {
    border-radius: var(--border-radius-23);
    background-color: #fff;
    color: var(--font-color);
    font-weight: bold;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0;
    cursor: pointer;
    border: none;
    height: 48px;
    padding: 0 20px;
}

.accordion .accordion-item-header:hover {
    background-color: #eeeeee;
}

.accordion .accordion-item-btn {
    border-radius: var(--border-radius-23);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.accordion .accordion-item-body-wrapper {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
}

.accordion .accordion-item-body {
    background-color: var(--greyscale-color);
    border-radius: var(--border-radius-23);
}
